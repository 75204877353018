import BaseSelect from '@/components/common/base-select/base-select.vue'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: false, massage: '输入编号', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '付款申请编号',
      prop: 'easNo',
      rules: [{ required: false, massage: '输入编号', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '供应商名称',
      prop: 'gysId',
      attrs: {
        placeholder: '请选择',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeQueryGys(data)
        }
      }
    },
    {
      label: '合同名称',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入'
      },
      isHidden: content.fold
    }
  ]
}
// 表格form
export const dataTable = () => {
  return [
    {
      label: '序号',
      prop: 'code'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      minWidth: '120px'
    },
    {
      label: '付款申请编号',
      prop: 'easNo',
      minWidth: '110px'
    },
    {
      label: '供应商名称',
      prop: 'gysName',
      'show-overflow-tooltip': true,
      align: 'left',
      minWidth: '110px'
    },
    {
      label: '工程项目',
      prop: 'projectName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '合同名称',
      prop: 'contractName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      'show-overflow-tooltip': true
    },

    {
      label: '合同金额(元)',
      prop: 'contractAmount',
      minWidth: '110px',
      align: 'right',
      formatter: row => {
        return (row.contractAmount ? row.contractAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '累计产值(元)',
      prop: 'paidAmount',
      minWidth: '110px',
      align: 'right'
    },
    {
      label: '已付金额(元)',
      prop: 'outputTotalAmount',
      align: 'right',
      minWidth: '110px',
      formatter: row => {
        return (row.outputTotalAmount ? row.outputTotalAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '150px',
      fixed: 'right'
    }
  ]
}
