<template>
  <div class="payment-order-Information">
    <!-- 查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '120px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
    <!-- 查询条件隐藏显示按钮 -->
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        />
      </div>
    </base-form>
    <!-- 表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :loadCount="loadCount"
      :queryParas.sync="queryParas"
      :api="api"
      :getApi="'getPage'"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :dataSource.sync="tableData"
      ref="tableData"
      :webPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          @click="viewDetails(scope.row.keyId)"
          content="查看"
          icon="iconfont iconchakan"
        />
        <icon-button
          @click="viewProcess(scope.row)"
          content="审批流程"
          icon="iconfont iconshenpiliucheng"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseForm from '@/components/common/base-form//base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { dataForm, dataTable } from './config'
import { assetinfoApi } from '@/api/assetApi'
import { supplierApi } from '@/api/companyApi'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { validURL } from '@/utils/validate'
export default {
  name: 'paymentOrderinformation',
  components: {
    BaseForm,
    BaseTable,
    IconButton,
    BaseButton
  },
  data () {
    return {
      dropOptions: [],
      type: '',
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      tableData: [],
      loadCount: 0,
      fold: false
    }
  },
  created () {
    // 申请主体下拉
    supplierApi.drop().then((res) => {
      this.dropOptions = res.data
    })
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return assetinfoApi
    }
  },
  methods: {
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 查看详情
    viewDetails (keyId) {
      this.$router.push({
        path: '/assetsManage/PaymentOrderDetails',
        query: { keyId: keyId }
      })
    },
    // 点击审批 跳转
    viewProcess (data) {
      if (data.oaUrl) {
        const href = data.oaUrl
        if (validURL(href)) {
          window.open(href, '_blank')
        } else {
          this.error('抱歉网络开小差了')
        }
      } else {
        this.error('抱歉网络开小差了')
      }
    },
    // 查询增加选项的name
    changeQueryGys (data) {
      if (data) {
        const obj = this.dropOptions.filter((item) => item.keyId === data)
        this.$set(this.queryParas, 'gysCompanyName', obj[0].enterpriseName)
      } else {
        this.$set(this.queryParas, 'gysCompanyName', '')
      }
    }
  },
  mounted () {
    this.handleFilter()
  }
}
</script>
<style lang="scss" scoped>
.payment-order-Information {
  height: 100%;
}
</style>
